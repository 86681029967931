<template>
  <Sidebar class="mobile-sidebar" :style="{ '--sidebar-margin-bottom': (marginBottom || 0) + 'px' }">
    <ul class="menu-items-list">
      <li :class="{ active: link.active || ($route.name === link.to.name) }" v-for="(link, i) of linksToShowWithAccount" :key="i">
        <router-link class="link" :to="link.to" v-close-menu>
          <i v-if="link.icon" class="icon" :class="link.icon"></i>
          {{ link.name }}
        </router-link>
      </li>
    </ul>
  </Sidebar>
</template>

<script>

import closeMenuDirective from '@/libs/Sidebar/directives/closeMenu.js';

import Sidebar from '@/libs/Sidebar/components/Sidebar.vue';

export default {
  components: {
    Sidebar,
  },
  directives: {
    closeMenu: closeMenuDirective,
  },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    marginBottom: {
      type: Number,
      required: false,
    },
  },
  computed: {
    linksToShow () {
      return this.links.filter(l => !l.hidden);
    },
    linksToShowWithAccount () {
      const linksToShow = [...this.linksToShow];

      if (this.isPublic) {
        return linksToShow;
      }

      const dashboardLink = linksToShow.find(l => l.name === 'home');
      if (dashboardLink) {
        dashboardLink.active = this.$route.path.startsWith('/dashboard');
      }

      if (!this.$oauth2.me) {
        linksToShow.push({ name: 'Login', to: { name: 'login' } });

        return linksToShow;
      }

      linksToShow.push({ name: 'Cambia password', to: { name: 'change-password' } });
      linksToShow.push({ name: 'Logout', to: { name: 'logout' } });

      return linksToShow;
    },
  },
};

</script>

<style lang="scss">

.mobile-sidebar {
  background: white;
  padding: 25px 20px;
  border: none;
  z-index: 1000;

  .menu-items-list li:not(.active) a {
    color: #333;
  }
}

</style>
